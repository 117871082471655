html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  height: 100%;
}
